import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAnchor.figmaUrl.android} codeUrl={checklists.componentAnchor.codeUrl.android} checklists={checklists.componentAnchor.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Anchor text is text that can be clicked and hyperlinked with other sites or pages. Anchor text can also act as an event listener for when the button is pressed.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "width": 150,
            "height": 50,
            "align": "center",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/react-native-anchor-1.png",
            "alt": "Legion Anchor React Native"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Anchor } from "@legion-crossplatform/ui";

<Anchor href="https://twitter.com/ditorahard">@ditorahard</Anchor>;
`}</code></pre>
    <div className="divi" />
    <h3>{`Target`}</h3>
    <p>{`Where to display the linked URL, as the name for a browsing context (a tab, window, or `}<inlineCode parentName="p">{`<iframe>`}</inlineCode>{`). Values are same like the vanilla HTML anchor.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Anchor } from "@legion-crossplatform/ui";

<Anchor href="https://twitter.com/ditorahard" target="_blank">
  @ditorahard
</Anchor>;
`}</code></pre>
    <h3>{`Color`}</h3>
    <p>{`Specifies the text color of the component. Accepts any valid CSS color value.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Anchor } from "@legion-crossplatform/ui";

<Anchor href="https://twitter.com/ditorahard" color="#ff00000">
  @ditorahard
</Anchor>;
`}</code></pre>
    <h3>{`Disabled`}</h3>
    <p>{`Default: `}<inlineCode parentName="p">{`false`}</inlineCode>{`. If true, disables the anchor element, preventing user interaction`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Anchor } from "@legion-crossplatform/ui";

<Anchor disabled href="https://twitter.com/ditorahard" target="_blank">
  @ditorahard
</Anchor>;
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`React.ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content to be displayed within the component. This can include text, elements, or other React components`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specifies the text color of the component. Accepts any valid CSS color value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default: `}<inlineCode parentName="td">{`false`}</inlineCode>{`. If true, disables the anchor element, preventing user interaction`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location to link to`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The relationship of the linked URL as space-separated link types`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`target`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Where to display the linked URL, as the name for a browsing context (a tab, window, or `}<inlineCode parentName="td">{`<iframe>`}</inlineCode>{`)`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      